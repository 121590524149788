import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import Layout from "../../components/Layout";
import Map from "../../components/Map/Map";
import DiscountModal from "../../components/Modals/DiscountModal/DiscountModal";
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal'
import { filterService } from "../../reducers/Services/actions";
import { getSingleDiscount } from "../../reducers/Discounts/actions";
import { scrollToElement } from '../../utils/util'
import { getMapData } from '../../reducers/Map/actions'
import { setModal, setDistrict, setCity } from '../../reducers/Home/actions'
import { navigate } from "gatsby";
import { connect } from 'react-redux'
import CheckIcon from '@mui/icons-material/Check';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";
import "./styles.scss";

const SingleDiscount = ({
  details,
  data,
  filterService,
  getSingleDiscount,
  getMapData,
  setDistrict,
  setCity,
  successModal,
  setModal,
  pageContext
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const aboutProduct = useRef(null);
  const howToUse = useRef(null);
  const couponInfo = useRef(null);
  const ourPartners = useRef(null);

  // const style = {
  //   display: 'none',
  //   background: 'red'
  // }

  // let url = "";
  let serviceId = pageContext && pageContext.item && pageContext.item.id;

  // if (typeof window !== "undefined") {
  //   url = new URL(window.location.href);
  //   serviceId = url.searchParams.get("id");
  // }

  useEffect(() => {
    if (serviceId && serviceId !== '') {
      getSingleDiscount({ id: serviceId })
    }
    getMapData()
  }, [getMapData, getSingleDiscount, serviceId])

  const handleResize = () => {
    if (typeof window !== "undefined" && window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const returnSeoDescription = (id) => {
    switch (id) {
      case 9:
        return 'Ако закупите 19 и повече палета блокове за зидария с марката YTONG THERMO, съгласно Правилата за участие в промоционална кампания на КСЕЛА България ЕООД ще получите един палет напълно безплатно.'
      case 8:
        return 'Ако закупите 19 и повече палета блокове за зидария с марката YTONG KOMFORT, съгласно Правилата за участие в промоционална кампания на КСЕЛА България ЕООД ще получите един палет напълно безплатно.'
      case 7:
        return 'Ако закупите 19 и повече палета блокове за зидария с марката YTONG AKUSTIK, съгласно Правилата за участие в промоционална кампания на КСЕЛА България ЕООД ще получите един палет напълно безплатно.'
      case 4:
        return ' Ако закупите 18 и повече палета блокове за зидария с марката Silka, съгласно Правилата за участие в промоционална кампания на КСЕЛА България ЕООД ще получите един палет напълно безплатно.'
      default: return ''
    }
  }

  return (
    <Layout description={returnSeoDescription(serviceId)}>
      <div className="single-discount-wrapper">
        <div className="single-discount-container">
          <Row>
            <Col lg={8}>
              <div className="single-discount-image-container d-grid">
                <GatsbyImage
                  image={getImage(pageContext && pageContext.item && pageContext.item.discount_image && pageContext.item.discount_image.localFile)}
                  alt=""
                  objectFit="cover"
                  layout="fixed"
                />
              </div>
              <Row className='mx-2'>
                <h2 className="my-4 font-weight-bold">
                  {pageContext && pageContext.item && pageContext.item.title}
                </h2>
                <a href="/discounts">
                  <p className="all-promotions">всички отстъпки</p>
                </a>
              </Row>
              <Row className="single-discount-tabs-row">
                <Col>
                  <div className="tabs-container">
                    <Nav className='px-2 mx-2 discount-nav-tabs' tabs>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            scrollToElement(couponInfo);
                          }}
                        >
                          Информация за ваучера
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            scrollToElement(howToUse);
                          }}>
                          Как да използвате ваучера
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            scrollToElement(aboutProduct);
                          }}>
                          За продукта
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          onClick={() => {
                            scrollToElement(ourPartners);
                          }}
                        >Нашите партньори</NavLink>
                      </NavItem>
                    </Nav>

                    <Row id="coupon-description">
                      <Col sm="12" className="anchor">
                        <h4 className="mt-5 font-weight-bold" ref={couponInfo}>
                          Информация за ваучера
                        </h4>
                        <div
                          dangerouslySetInnerHTML={{ __html: pageContext && pageContext.item && pageContext.item.description }}
                        />
                      </Col>
                    </Row>

                    <Row id="how-to">
                      <Col className="anchor" sm="12">
                        <h4 className="mt-5 font-weight-bold" ref={howToUse}>
                          Как да използвате ваучера
                        </h4>
                        <div
                          dangerouslySetInnerHTML={{ __html: pageContext && pageContext.item && pageContext.item.usage }}
                        />
                      </Col>
                    </Row>

                    <Row id="product-description">
                      <Col className="anchor" sm="12">
                        <h4 className="mt-5 font-weight-bold" ref={aboutProduct}>
                          За продукта
                        </h4>
                      </Col>
                      <Col className="anchor" lg={5}>
                        <div className="product-description__image-container d-grid">
                          <GatsbyImage
                            image={getImage(pageContext && pageContext.item && pageContext.item.product_image && pageContext.item.product_image.localFile)}
                            alt=""
                            objectFit="contain"
                            layout="fixed"
                          />
                        </div>
                      </Col>
                      <Col className={`${isMobile ? "anchor pt-4" : "anchor"}`} lg={7} sm={12}>
                        <div
                          dangerouslySetInnerHTML={{ __html: pageContext && pageContext.item && pageContext.item.product_description }}
                        />
                      </Col>
                      <Col sm={12}>
                        <div
                          className="mt-4"
                          dangerouslySetInnerHTML={{ __html: pageContext && pageContext.item && pageContext.item.product_features }}
                        />
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <div className="details-image-container d-grid">
                          <GatsbyImage
                            image={getImage(pageContext && pageContext.item && pageContext.item.final_image && pageContext.item.final_image.localFile)}
                            alt=""
                            objectFit="cover"
                            layout="fixed"
                          />
                        </div>
                        <div
                          className="mt-3"
                          dangerouslySetInnerHTML={{ __html: pageContext && pageContext.item && pageContext.item.final_description }}
                        />
                      </Col>
                    </Row>

                    <Row id="partners">
                      <Col sm="12" className="anchor mb-5">
                        <h4 className="mt-5 mb-3 font-weight-bold" ref={ourPartners}>Нашите партньори</h4>
                        <Map
                          onClickChange={(name) => {
                            let city = data.filter(c => c.name === name)
                            setDistrict(city[0])
                            setCity(null)
                            if (name === 'София') {
                              setDistrict({ id: 24, name: 'София-град' })
                              filterService({
                                page: 1,
                                districtId: 24,
                                service: 'dealers'
                              });
                              navigate('/services?service=dealers')
                              return
                            }
                            if (name === 'София-област') {
                              setDistrict({ id: 23, name: 'София' })
                              filterService({
                                page: 1,
                                districtId: 23,
                                service: 'dealers'
                              });
                              navigate('/services?service=dealers')
                              return
                            }
                            filterService({
                              page: 1,
                              districtId: Number(city[0].id),
                              service: 'dealers'
                            });
                            navigate('/services?service=dealers')
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            {isMobile
              ? <Col sm={12} className="sticky-bottom p-3">
                <div className="single-discount-right-container">
                  <h5 className='font-weight-bold'>
                    {details && details.title}
                  </h5>
                  <hr />
                  <div className="single-discount-right-inner">
                    <Button
                      className="px-2 py-2 my-1 single-discount-btn text-uppercase"
                      onClick={() => setModalOpen(!isModalOpen)}
                    >
                      Свалете ваучер!
                    </Button>
                  </div>
                </div>
              </Col>
              :
              <Col lg={4}>
                <div className="single-discount-right-container">
                  <div className="single-discount-right single-discount-border">
                    <h4>{pageContext && pageContext.item && pageContext.item.title}</h4>
                    <hr />
                    <div className="single-discount-right-inner">
                      <div className='pt-3'>
                        <div className="pb-3 d-flex align-items-center">
                          <span className="pr-3 check-icon" role="img" aria-label="check">
                            <CheckIcon />
                          </span>
                          <span className="bullet-text">
                            Свалянето на ваучера е необвързващо
                          </span>
                        </div>
                        <div className="pb-3 d-flex align-items-center">
                          <span className="pr-3 check-icon" role="img" aria-label="check">
                            <CheckIcon />
                          </span>
                          <span className="bullet-text">
                            Активираният ваучер е валиден 12 месеца
                          </span>
                        </div>
                        <div className="pb-3 d-flex align-items-center">
                          <span className="pr-3 check-icon" role="img" aria-label="check">
                            <CheckIcon />
                          </span>
                          <span className="bullet-text">
                            Материали с отлично качество
                          </span>
                        </div>
                      </div>
                      <Button
                        className=" py-3 my-4 single-discount-btn text-uppercase"
                        onClick={() => {
                          setModalOpen(!isModalOpen)
                        }}
                      >
                        Свалете ваучер!
                      </Button>
                    </div>
                  </div>
                  <div>
                    <FacebookShareButton
                      url={"https://www.facebook.com/Building-companion-Bulgaria-106030925324315"}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <LinkedinShareButton
                      className="margin-left5"
                      url={"https://www.linkedin.com/company/xella-bulgaria/?viewAsMember=true"}
                    >
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                  </div>
                </div>

              </Col>
            }
          </Row>

          <DiscountModal
            isOpen={isModalOpen}
            onClick={() => setModalOpen(!isModalOpen)}
            id={serviceId}
            title={pageContext && pageContext.item && pageContext.item.title}
          />
          <ConfirmationModal
            title={`Ваучерът е изпратен на посочения имейл.`}
            isOpen={successModal}
            onClick={() => setModal()}
          />
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    details: state.discounts.details,
    data: state.map.data,
    successModal: state.home.successModal
  };
};

const mapDispatchToProps = {
  filterService,
  getSingleDiscount,
  getMapData,
  setModal,
  setDistrict,
  setCity
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleDiscount);