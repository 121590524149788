import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  Container,
  Modal,
  Row,
  Col,
} from "reactstrap";
import CustomizedCheckbox from "../../Checkbox/Checkbox";
import someImg from "../../../assets/images/Group 306.svg";
import { postDiscount } from '../../../reducers/Discounts/actions'
import { validateEmail, validatePhone, nameRegex, phoneRegex, locationRegex } from '../../../utils/util'
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import "./styles.scss";

const DiscountModal = ({
  postDiscount,
  isOpen,
  onClick,
  id,
  title }) => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [location, setLocation] = useState('')
  const [gdpr, setGDPR] = useState(false)
  const [terms, setTerms] = useState(false)
  const [marketing, setMarketing] = useState(false)
  const [validate, setValidate] = useState(false)

  useEffect(() => {
    setName('')
    setEmail('')
    setPhone('')
    setLocation('')
    setGDPR(false)
    setTerms(false)
    setMarketing(false)
    setValidate(false)
  }, [])

  const validateFields = () => {
    if (name.length === 0 || !nameRegex.test(name) || !phoneRegex.test(phone) || phone.length === 0 ||
      !validateEmail(email) || email.length === 0 || location.length === 0 || location.length > 35 ||
      !gdpr || !terms || !marketing) {
      return true
    } else return false
  }

  const validateInputs = (name, phone, email, location) => {
    if (!nameRegex.test(name) || name.length > 35 || name.length === 0) {
      return 'Невалидно име! Допустими символи: латиница и кирилица.'
    }
    if (!phoneRegex.test(phone) || phone.length > 25 || phone.length === 0) {
      return 'Невалиден телефонен номер! Допустими символи: само цифри.'
    }
    if (!validateEmail(email) || email.length > 254 || email.length === 0) {
      return 'Невалиден имейл адрес! Допустими символи: латиница, цифри, и специални символи.'
    }
    if (location.length > 35 || location.length === 0) {
      return 'Невалидна локация! Допустими символи до 35'
    }
    if (!gdpr) {
      return 'Липсва съгласие за обработка на лични данни.'
    }
    if (!terms) {
      return 'Липсва съгласие за правилата за условията на промоцията.'
    }
    if (!marketing) {
      return 'Липсва съгласие за правилата за ползване на уебсайта.'
    }
    return ''
  }

  return (
    <Modal className="w-100 discount-modal" size="xl" isOpen={isOpen}>
      <Row className="discount-modal-row">
        <Col lg={8} md={12} sm={12}>
          <div className='p-5'>
            <Row>
              <Col sm={12}>
                <h4 className="font-weight-bold no-decoration text-center mb-4">
                  Попълнете формата и свалете ваучер!
                </h4>
              </Col>
            </Row>

            <Row className="modal-input-fields pt-2 pb-1 mx-auto">
              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2 ">
                  <Input
                    maxLength={35}
                    placeholder="Име и фамилия"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onChange={e => setName(e.target.value)}
                  />
                </InputGroup>
              </Col>

              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2 ">
                  <Input
                    maxLength={25}
                    type='number'
                    placeholder="Телефон"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onChange={e => setPhone(e.target.value)}
                  />
                </InputGroup>
              </Col>

              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2 ">
                  <Input
                    type='email'
                    maxLength={254}
                    placeholder="Имейл"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onChange={e => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Col>

              <Col sm={12} lg={6}>
                <InputGroup className="input-group-custom mb-2 ">
                  <Input
                    type='text'
                    maxLength={25}
                    placeholder="Локация"
                    className="input-field py-3 px-3 border-radius box-shadow"
                    onChange={e => setLocation(e.target.value)}
                  />
                </InputGroup>
              </Col>
            </Row>
            <div className='my-3'>
              <Row className="privacy-checkbox-row w-100">
                <div className='py-2 d-flex align-items-center'>
                  <CustomizedCheckbox
                    checked={gdpr}
                    onChange={() => setGDPR(!gdpr)}
                  />
                  <label className="checkbox-label" htmlFor="privacy">
                    <p className='my-0'>Съгласен съм личните ми данни да бъдат обработвани от ‘‘КСЕЛА България‘‘ ЕООД в съответствие с <a href='#' className='blue-link' onClick={() => window.open(`https://building-companion.bg/GDPR`, "_blank")}>Политиката за защита на личните данни на КСЕЛА България</a>.</p>
                  </label>
                </div>
                <div className='py-2 d-flex align-items-center'>
                  <CustomizedCheckbox
                    checked={terms}
                    onChange={() => setTerms(!terms)}
                  />
                  <label className="checkbox-label" htmlFor="privacy">
                    <p className='my-0'>Съгласен съм с <a href='#' className='blue-link' onClick={() => window.open(`https://building-companion.bg/discounts-terms-of-use`, "_blank")}>Условията на промоцията</a>.</p>
                  </label>
                </div>
                <div className='py-2 d-flex align-items-center'>
                  <CustomizedCheckbox
                    checked={marketing}
                    onChange={() => setMarketing(!marketing)}
                  />
                  <label className="checkbox-label" htmlFor="privacy">
                    <p className='my-0'>Съгласен съм и приемам <a href='#' className='blue-link' onClick={() => window.open(`https://building-companion.bg/terms-and-conditions?terms-and-conditions=0`, "_blank")}>правилата за ползване на уебсайта</a>.</p>
                  </label>
                </div>

              </Row>
            </div>
            <hr />
            <div>
              {
                validateFields() && validate &&
                <div className='data-alert mt-3 mx-auto px-2'>
                  {validateInputs(name, phone, email, location)}
                </div>
              }
            </div>
            <div className="no-decoration text-center text-black-50">
              <div className="no-decoration-inner">
                <div className="text-area text-align-center py-3">
                  Очаквайте обаждане от наш служител за верифициране на данните и активиране на Вашия ваучер!
                </div>
              </div>

              <Container className="btn-container d-flex justify-content-center pt-2 ">
                <Button
                  className="single-project-button mt-1 text-uppercase mb-3"
                  onClick={() => {
                    if (validateFields()) {
                      setValidate(true)
                      return
                    }
                    postDiscount({
                      discount_id: Number(id),
                      full_name: name,
                      phone_number: phone,
                      email,
                      location,
                      gdpr_consent: gdpr,
                      terms_consent: terms,
                      marketing_consent: marketing
                    })
                    setName('')
                    setEmail('')
                    setPhone('')
                    setLocation('')
                    setGDPR(false)
                    setTerms(false)
                    setMarketing(false)
                    setValidate(false)
                    onClick()
                  }}
                >
                  СВАЛИ ВАУЧЕРА!
                </Button>
              </Container>
            </div>
          </div>
        </Col>
        <Col lg={4} md={12} sm={12}>
          <div className="discount-modal-right-container px-3">
            <div className="close-btn-container d-flex justify-content-end">
              <i
                role="none"
                className="close-btn"
                onClick={() => {
                  setName('')
                  setEmail('')
                  setPhone('')
                  setLocation('')
                  setGDPR(false)
                  setTerms(false)
                  setMarketing(false)
                  setValidate(false)
                  onClick()
                }}>
                <CloseIcon />
              </i>
            </div>
            <div className="inner-container px-3 pb-5">
              <h4 className="title">
                {title}
              </h4>

              <div className='py-3'>
                <div className="pb-3 d-flex align-items-start">
                  <span className="pr-3 white-check-icon" role="img" aria-label="check">
                    <CheckIcon color="primary" />
                  </span>
                  <span className="text">
                    Продукт от утвърден производител
                  </span>
                </div>
                <div className="pb-3 d-flex align-items-start">
                  <span className="pr-3 white-check-icon" role="img" aria-label="check">
                    <CheckIcon color="primary" />
                  </span>
                  <span className="text">
                    {`Срещу активирания ваучер получавате ${title?.charAt(0).toLowerCase() + title?.substring(1)} при закупуване на ${title && title.indexOf('Silka') >= 0 ? '18' : '19'} палета от същия продукт`}
                  </span>
                </div>
                <div className="pb-3 d-flex align-items-start">
                  <span className="pr-3 white-check-icon" role="img" aria-label="check">
                    <CheckIcon color="primary" />
                  </span>
                  <span className="text">
                    Активираният ваучер е валиден 12 месеца
                  </span>
                </div>
              </div>
              <img alt="discount" src={someImg} className="discount-img align-self-center" />
            </div>
          </div>
        </Col>
      </Row>
    </Modal >
  );
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  postDiscount
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscountModal);
